import { useEffect, useState } from 'react'
import classNames from 'classnames'
import { THEME, useTheme, tableState } from 'state'
import {
    LIQUID_STAKING_PROVIDER_KEY,
    OPERATOR_KEY,
    PageSizes,
} from 'utils/constants'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import { Loader } from 'components/ui/loader'
import { ToggleButton } from 'components/ui/buttons/toggleButton'
import { ExpandableVerifiedTag } from 'components/ui/expandableVerifiedTag'
import { TableView } from 'components/ui/tableViewSelector'

import styles from './table.module.scss'
import { GTMEvent, logEvent } from 'utils/GTM'
import { ExpandableProTag } from 'components/ui/expandableProTag'

export const NoDataStatus = ({ isLoading = false }) => {
    return (
        <div className={styles.statusWrap}>
            {isLoading ? (
                <Loader centered />
            ) : (
                <p>
                    There are no results for your search. Please revise your
                    filters.
                </p>
            )}
        </div>
    )
}

export const Card = ({
    withExpansion = true,
    withVerifiedTag = false,
    renderMetricsCard = () => {},
    renderExpandedCard = () => {},
    asset,
    isPro = false,
}) => {
    const isTableExpandedValue = tableState.isTableExpanded.value
    const [isExpanded, setIsExpanded] = useState(isTableExpandedValue)
    useEffect(() => {
        setIsExpanded(isTableExpandedValue)
    }, [isTableExpandedValue])

    const theme = useTheme()

    return (
        <div className={styles.card} data-theme={theme}>
            <div className={styles.mainRow}>
                {withVerifiedTag && (
                    <div className={styles.verifiedTag}>
                        {isPro ? (
                            <ExpandableProTag />
                        ) : (
                            <ExpandableVerifiedTag />
                        )}
                    </div>
                )}
                <div className={styles.basis}>
                    {renderMetricsCard()}
                    {withExpansion && (
                        <div
                            className={classNames(styles.more, {
                                [styles.expanded]: isExpanded,
                            })}
                        >
                            {renderExpandedCard()}
                        </div>
                    )}
                </div>
            </div>
            <div
                className={classNames(styles.expandBtnWrap, {
                    [styles.hidden]: !withExpansion,
                })}
            >
                <div
                    className={styles.expandBtn}
                    onClick={() => {
                        setIsExpanded(!isExpanded)
                        if (asset) {
                            logEvent(GTMEvent.ExpandTableClick, {
                                asset: asset?.slug,
                            })
                        }
                    }}
                >
                    <ToggleButton expanded={isExpanded} />
                </div>
            </div>
        </div>
    )
}

export const Row = ({
    isExpanded = false,
    item = null,
    isAssetRow = false,
    withExpansion = true,
    withVerifiedTag = false,
    renderMetricsRow = () => {},
    renderExpandedRow = () => {},
    isExpandedByDefault = false,
    asset,
    isPro = false,
}) => {
    const { queryParams } = useTableArchiveContext()

    const theme = useTheme()

    return (
        <div
            className={classNames(styles.row, {
                '!bg-white': theme === THEME.LIGHT,
                '!bg-contrast-1': theme === THEME.DARK,
            })}
        >
            <div
                className={classNames(styles.mainRow, {
                    [styles.withExpansion]: withExpansion,
                })}
            >
                {withVerifiedTag && (
                    <div className={styles.verifiedTag}>
                        {isPro ? (
                            <ExpandableProTag />
                        ) : (
                            <ExpandableVerifiedTag />
                        )}
                    </div>
                )}
                <div className={styles.basis}>
                    {renderMetricsRow()}
                    {withExpansion && (
                        <div
                            className={classNames(styles.more, {
                                [styles.expanded]: isExpanded,
                            })}
                        >
                            {renderExpandedRow()}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export const Table = ({
    data = null,
    isLoading = false,
    noDataFound = false,
    isEnd = false,
    getItemsFromData = () => {},
    renderItemComponent = () => {},
    profileItem = null,
}) => {
    const { selectedTableView, paginationParams, setHasMore, queryParams } =
        useTableArchiveContext()

    const categoryKey = queryParams?.category?.key

    const pageSizeNumber = Number(
        paginationParams?.pageSize?.key ?? PageSizes.Twenty
    )

    useEffect(() => {
        setHasMore(!isEnd)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnd])

    const isLoadingData = !data && isLoading

    let rowItems = []
    if (isLoadingData) {
        rowItems = [...Array(pageSizeNumber).keys()]
    } else if (data?.length) {
        rowItems = getItemsFromData(data)
    }

    const hasOperatorList =
        !isLoadingData &&
        profileItem?.type?.key === LIQUID_STAKING_PROVIDER_KEY &&
        categoryKey !== OPERATOR_KEY

    const allItems = [...rowItems]

    // Map operators to their parent reward option and show in expanded view, if there is no parent, then show in main list
    if (hasOperatorList) {
        rowItems = rowItems.filter(item => item?.type?.key !== OPERATOR_KEY)
        rowItems = [
            ...rowItems,
            ...allItems.filter(
                item =>
                    item?.type?.key === OPERATOR_KEY &&
                    // search for parent
                    !allItems.some(i => {
                        return (
                            i?.inputAssets?.[0]?.slug ===
                                item?.inputAssets?.[0]?.slug && i !== item
                        )
                    })
            ),
        ]
    }

    if (!isLoading && noDataFound) {
        return (
            <div className={styles.dataStatus}>
                <p>
                    There are no results for your search. Please revise your
                    filters.
                </p>
            </div>
        )
    }
    return (
        <div
            className={
                selectedTableView === TableView.Grid
                    ? styles.grid
                    : styles.table
            }
        >
            {rowItems?.map((item, idx) => {
                const operators =
                    hasOperatorList &&
                    allItems.filter(i => {
                        return (
                            i?.inputAssets?.[0]?.slug ===
                                item?.inputAssets?.[0]?.slug && i !== item
                        )
                    })
                return (
                    <div key={`item-${item?.id ?? ''}-${idx} + 1}`}>
                        {renderItemComponent(
                            selectedTableView === TableView.Grid,
                            {
                                index: idx + 1,
                                item: isLoadingData ? null : item,
                                isLoading: isLoadingData,
                                operators,
                            }
                        )}
                    </div>
                )
            })}
        </div>
    )
}
