import Image from 'next/image'
import styles from './feature.module.scss'

export const Feature = ({ label = '', description = '', icon = '' }) => {
    return (
        <div className={styles.feature}>
            {icon && (
                <div className={styles.icon}>
                    <Image
                        src={icon}
                        alt={label}
                        fill
                        sizes='(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw'
                    />
                </div>
            )}
            <h2 className={styles.label}>{label}</h2>
            <p className='text-description'>{description}</p>
        </div>
    )
}
