import { useEffect, useRef } from 'react'
import { useIsVisible } from 'hooks'
import { tableState } from 'state'

export const TableArchiveWithControl = ({
    forceHideControl = false,
    children,
}) => {
    const elRef = useRef(null)
    const isInView = useIsVisible(elRef)

    // Table control is a part of global page control
    const { showTableControl, isTouchedExpansion } = tableState
    useEffect(() => {
        // Show the expand/collapse button only when the table is visible
        showTableControl.value = isInView && !forceHideControl
        return () => {
            showTableControl.value = false
            isTouchedExpansion.value = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInView, forceHideControl])

    return <div ref={elRef}>{children}</div>
}
