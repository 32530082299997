import { useState } from 'react'
import dynamic from 'next/dynamic'
import {
    DEFAULT_ORDER,
    DEFAULT_ASSET_TIMEFRAME,
    TIMEFRAME_CHOICES,
    ORDER_CHOICES,
    DEFAULT_ASSET_CATEGORY,
    ASSET_CATEGORIES,
} from 'utils/constants'
import { Button } from 'components/ui/buttons/button'
import { Hero } from 'components/home/hero'
import { ApiBanner } from 'components/ads/apiBanner'

import { DropdownSelect } from 'components/forms/dropdowns/dropdownSelect'
import { TableArchiveWithControl } from 'components/tables/tableArchiveWithControl'
import { VspSection } from './vspSection'

import styles from './home.module.scss'
import { TableArchiveContextProvider } from 'contexts/tableArchiveContext'
import { START_TIME_SECONDS, VIDEO_ID } from 'components/calculator/constants'
import classNames from 'classnames'
import { AdBanner } from 'components/ads/adBanner'
import { ElementId, SlotId } from 'components/ads/utils'
import { NewsletterSection } from 'components/ads/newsletterSection'
import { DEFAULT_ASSETS_SORT_BY } from 'components/assets/constants'
import { getAssetSortByOptionsPerCategory } from 'components/assets/archive/utils'
import { CardsOnlyAssetsTable } from 'components/assets/archive/assetsTable'
import { Link } from 'components/ui/links/link'

import { YoutubeLink } from 'components/ui/links/youtubeLink'

// Dynamically scales overlapping photos depending on the browser's viewport width
// Import dynamically to prevent hydration
const SummitBanner = dynamic(
    () =>
        import('components/ads/summitBanner').then(
            module => module.SummitBanner
        ),
    {
        ssr: false,
    }
)

const Calculator = dynamic(
    () => import('components/calculator').then(module => module.Calculator),
    {
        ssr: false,
    }
)

export function HomePage() {
    const [params, setParams] = useState({
        category: DEFAULT_ASSET_CATEGORY,
        sortBy: DEFAULT_ASSETS_SORT_BY,
        timeframe: DEFAULT_ASSET_TIMEFRAME,
        order: DEFAULT_ORDER,
    })
    return (
        <>
            <div className='bg'>
                <div className={styles.backgroundGradient1}></div>
                <div className={styles.backgroundGradient2}></div>
                <div className={styles.backgroundGradient3}></div>
            </div>

            <div className={classNames(styles.home, 'dark:bg-black')}>
                <div className={`container`}>
                    <Hero />
                    <div className={styles.tableWrap}>
                        <div className={styles.topWrap}>
                            <div className={styles.headerWrap}>
                                <h2>Explore Top Staking Assets</h2>
                                <p className='text-description'>
                                    Compare Their Performance with Staking
                                    Analytics - Then Investigate Deeper.
                                </p>
                            </div>
                            <div className={styles.filtersWrap}>
                                <DropdownSelect
                                    choices={ORDER_CHOICES}
                                    selected={params?.order}
                                    onSelect={newOrder => {
                                        setParams({
                                            ...params,
                                            order: newOrder,
                                        })
                                    }}
                                    small
                                />
                                <DropdownSelect
                                    choices={TIMEFRAME_CHOICES}
                                    selected={params?.timeframe}
                                    onSelect={newTimeframe => {
                                        setParams({
                                            ...params,
                                            timeframe: newTimeframe,
                                        })
                                    }}
                                    small
                                />
                                <DropdownSelect
                                    className={styles.sortByFilter}
                                    choices={getAssetSortByOptionsPerCategory(
                                        params?.category?.key
                                    )}
                                    selected={params?.sortBy}
                                    onSelect={newSortBy => {
                                        setParams({
                                            ...params,
                                            sortBy: newSortBy,
                                        })
                                    }}
                                    small
                                />
                                <DropdownSelect
                                    className={styles.categoryFilter}
                                    choices={ASSET_CATEGORIES}
                                    selected={params?.category}
                                    onSelect={newCategory => {
                                        setParams({
                                            ...params,
                                            category: newCategory,
                                        })
                                    }}
                                    small
                                />
                            </div>
                        </div>
                        <TableArchiveContextProvider
                            params={params}
                            pageSize={8}
                        >
                            <TableArchiveWithControl forceHideControl={false}>
                                <CardsOnlyAssetsTable />
                                <div className='flex py-5'>
                                    <Link
                                        className='mx-auto'
                                        href={`/assets/${
                                            params?.category?.key ?? ''
                                        }`}
                                        blank={false}
                                    >
                                        <Button
                                            variant='black'
                                            iconClass='icon-arrow-right !size-[12px]'
                                        >
                                            View More
                                        </Button>
                                    </Link>
                                </div>
                            </TableArchiveWithControl>
                        </TableArchiveContextProvider>
                    </div>
                    {/* <CtaHomePage className='mb-12' /> */}
                    <div className='mb-14'>
                        <div className={styles.topWrap}>
                            <div className={styles.headerWrap}>
                                <h2>Calculate Your Staking Rewards</h2>
                                <p className='text-description'>
                                    Examine the long-term compounding effect of
                                    staking - per asset, provider, staking
                                    amount and price scenario.
                                </p>
                            </div>
                            <div className={styles.linkWrap}>
                                <YoutubeLink
                                    href={`https://youtube.com/watch?v=${VIDEO_ID}&t=${START_TIME_SECONDS}s`}
                                    label={'Learn how to use the calculator'}
                                />
                            </div>
                        </div>
                        <Calculator scenariosExpandable noRedirect />
                    </div>
                    <div className={styles.adWrap}>
                        <AdBanner
                            slotId={SlotId.FooterBanner}
                            id={ElementId.FooterBanner}
                        />
                    </div>
                    <div className={styles.vspWrap}>
                        <VspSection />
                    </div>
                    <div className={styles.banners}>
                        <ApiBanner />
                        <SummitBanner />
                        <NewsletterSection />
                    </div>
                </div>
            </div>
        </>
    )
}
