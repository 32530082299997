export const DEFAULT_STAKING_AMOUNT_USD = 10000

export const FORTUNE_AMOUNT_USD = 100000000

export const DEFAULT_STAKING_TIME_YEARS = 1

export const DEFAULT_STAKING_TIME_OBJECT = {
    years: DEFAULT_STAKING_TIME_YEARS,
    months: 0,
    days: 0,
}

export const MIN_STAKING_TIME_OBJECT = {
    years: 0,
    months: 0,
    days: 1,
}

export const MAX_STAKING_TIME_OBJECT = {
    years: 10,
    months: 0,
    days: 0,
}

export const FORMULA_TYPE_REWARD = 'rewardFormula'
export const FORMULA_TYPE_INFLATION = 'inflationFormula'

export const ASSETS_WITH_OPT_STAKING_FREQUENCY = ['cosmos', 'kusama', 'elrond']

export const TOOLTIPS = {
    assetsCount: {
        title: 'Number of Assets',
        text: 'The sum of all crypto assets, which are featured in the Staking Calculator. We deem the data of those assets fully accurate within the provided limitations.',
    },
    providersCount: {
        title: 'Number of Providers',
        text: 'The sum of all staking providers, which are featured in the Staking Calculator. We include all providers who have claimed their profile on StakingRewards.com. We deem the data of those providers fully accurate within the provided limitations.',
    },
    step1: {
        title: 'Step #1 - Choose Asset',
        text: 'Choose from 50 crypto assets in 8 categories. Assets are sorted by popularity on StakingRewards.com. Find assets by scrolling, selecting another category, or search (case sensitive) directly across all categories by typing in their name or symbol. Click refresh to reset to the most popular asset.',
    },
    step2: {
        title: 'Step #2 - Choose Provider',
        text: 'Choose from 240 providers in 6 categories. Providers are sorted by Assets Under Management descending, to show you the largest first. Find providers by scrolling, selecting another category, or search (case sensitive) directly across all categories by typing in their name. Select the Solo Staking Option to see results for running your own validator. Only providers who claimed their StakingRewards.com profile are listed here.',
    },
    step3: {
        title: 'Step #3 - Enter Staking Amount',
        text: 'Enter the amount you would like to stake. Toggle between USD and native token, to select the desired input currency. The amount remains equivalent in value when toggling between currencies. The maximum input is the USD equivalent of the Market Cap.',
    },
    earnings: {
        title: '1d - 365d Earnings',
        text: 'The 1d, 7d, 30d, and 365d earnings show you the expected returns assuming a respective, 1d, 7d, 30d, and 365d staking time. The earnings are non-compounded and limited by the total Market Cap of the network.',
    },
    rewards: {
        title: 'Chart explanation',
        text: `Learn more about the different charts displayed.`,
        node: (
            <div>
                <p>Learn more about the different charts displayed:</p>
                <h4>Non-Compounded</h4>
                <p>
                    The non-compounded chart shows the estimated returns of
                    staking without restaking accumulated rewards. Compare this
                    chart with the compounded chart to see the difference that
                    restaking your rewards can make in your staking strategy.
                </p>
                <h4>Compounded</h4>
                <p>
                    The compounded chart shows the estimated returns of staking
                    considering constant restaking of accumulated rewards, based
                    on the presented reward frequency in the block below. It’s
                    important to note that this calculation doesn’t include any
                    gas fees that may be associated with reinvesting rewards.
                </p>
                <h4>Selling Rewards</h4>
                <p>
                    The selling rewards chart shows the estimated returns of
                    staking when selling the accumulated rewards at the
                    respective market price at the time of receipt. This line
                    does not show up when the input currency is the token
                    amount, because when you sell your rewards, you end up with
                    the same amount in token amount as when you started staking.
                </p>
                <h4>Not Staking</h4>
                <p>
                    The not staking chart shows the expected returns considering
                    pure price appreciation with no staking. We assume most of
                    the price increase/decrease happens towards the beginning of
                    the selected staking time, with the selected expected price
                    reached at the end of the staking time. This line does not
                    show up when the input currency is the token amount, because
                    even with price changes, the rewards remain constant in
                    token terms.
                </p>
            </div>
        ),
    },
    stakingTime: {
        title: 'Staking Time',
        text: 'Enter any time between 1 day to 10 years with the respective years, months, or days. The chart shows a time frame of 3 years by default, and if you put in a time longer than 3 years, it switches to 10 years.',
    },
    expectedPrice: {
        title: 'Expected Price',
        text: 'Enter the price you expect after the selected staking time. The default staking time is 1 year, but can be adjusted. All calculator results take into account the selected price increase/decrease. It is assumed that most of the price increase/decrease happens towards the beginning of the staking time, as seen in the “Not Staking” chart. The default price is the current market average.',
    },
    totalRewardRate: {
        title: 'Total Reward Rate',
        text: 'The Total Reward Rate provides stakers the cumulative percentage return on their input amount. It takes into account the selected time, expected price, and any other input. The Total Rewards Rate allows stakers to assess potential earnings and determine the staking period that would best suit their needs.',
    },
    rewardFrequency: {
        title: 'Reward Frequency',
        text: 'The Reward Frequency metric indicates how often stakers can expect to receive rewards, impacting the compounding restaking interval. For most assets, the Reward Frequency is assumed to be daily. In some cases, the Reward Frequency is per block or per epoch.',
    },
    adjReward: {
        title: 'Adjusted Reward',
        text: 'The Adjusted Reward takes into account the network inflation rate, which can impact the user’s overall share in the network. If the inflation is higher than the Reward Rate, then this number is negative.',
    },
    networkShare: {
        title: 'Network Share',
        text: 'Network Share shows stakers the total share of the network token supply, indicating the potential impact of the adjusted reward over time.',
    },
    optStakingFrequency: {
        title: 'Optimal Staking Frequency',
        text: 'The optimal staking frequency metric enables stakers to determine the ideal compounding interval for maximizing the cost-rewards ratio of their staking rewards. This metric takes into account the average transaction costs associated with restaking rewards.',
    },
}

export const CalculatorGlobalMetrics = {
    Assets: 'assets_count',
    Providers: 'providers_count',
}
export const GLOBAL_METRIC_GROUPS = [
    {
        key: CalculatorGlobalMetrics.Assets,
        label: 'Assets',
        withPercentage: false,
        width: 100,
        tooltipTextObj: TOOLTIPS.assetsCount,
    },
    {
        key: CalculatorGlobalMetrics.Providers,
        label: 'Providers',
        withPercentage: false,
        width: 100,
        tooltipTextObj: TOOLTIPS.providersCount,
    },
]

export const VIDEO_ID = 'kOtWyQgSTd0'
export const START_TIME_SECONDS = 82
