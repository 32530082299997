import dynamic from 'next/dynamic'
import { useTableArchiveContext } from 'contexts/tableArchiveContext'
import {
    BITCOIN_AND_OTHERS_KEY,
    PageSizes,
    STABLECOIN_KEY,
} from 'utils/constants'
import { Table } from 'components/tables/table'
import { useAssetsData } from './hooks'
import { getNetStakingFlowItem } from './utils'

import styles from './assetsTable.module.scss'
import {
    fetchProviderCountForAssets,
    fetchRewardOptionsForAssets,
} from 'data/queries'
import { useQuery } from '@tanstack/react-query'

const AssetTableRow = dynamic(
    () => import('./assetTableRow').then(mod => mod.AssetTableRow),
    {
        ssr: false,
    }
)

const AssetCard = dynamic(
    () => import('./assetCard').then(mod => mod.AssetCard),
    {
        ssr: false,
    }
)

export const ArchiveTable = () => {
    const { data, isLoading, noDataFound, isEnd } = useAssetsData()
    const { queryParams } = useTableArchiveContext()

    const isLoadingData = !data && isLoading

    let metricsItems = []
    if (!isLoadingData && data?.length) {
        const allItems = [...data]
        if (Boolean(allItems?.length)) {
            metricsItems = [
                ...allItems.flatMap(item => item?.assetsWithMetrics ?? []),
            ]
        }
    }

    const assetSlugs = data?.[0]?.assets?.map(a => a?.slug);

    const isStablecoins = queryParams?.category?.key === STABLECOIN_KEY
    const isBtc = queryParams?.category?.key === BITCOIN_AND_OTHERS_KEY

    // Fetch provider counts for assets
    const {
        data: providerCounts = {},
        isLoading: isLoadingProviderCounts,
        error: providerCountsError,
    } = useQuery({
        queryKey: ['providerCounts', assetSlugs],
        queryFn: async () => {
            if (!assetSlugs) return {};
            const providerCounts = await fetchProviderCountForAssets(assetSlugs);
            return Object.entries(providerCounts || {}).reduce((acc, [key, value]) => {
                acc[key.replaceAll('__', '-')] = value?.providers?.count;
                return acc;
            }, {});
        },
        enabled: Boolean(assetSlugs && (isStablecoins || isBtc))
    });

    const {
        data: stablecoinData = [],
        isLoading: isLoadingStablecoinData,
        error: stablecoinDataError,
    } = useQuery({
        queryKey: ['stablecoinData', assetSlugs],
        queryFn: async () => {
            if (!assetSlugs) return [];
            const result = await fetchRewardOptionsForAssets(assetSlugs);
            return getRewardRates(result);
        },
        enabled: Boolean(assetSlugs && (isStablecoins || isBtc))
    });

    return (
        <Table
            data={data}
            isLoading={isLoading}
            noDataFound={noDataFound}
            isEnd={isEnd}
            getItemsFromData={data => {
                const allItems = [...data]
                if (Boolean(allItems?.length)) {
                    return [...allItems.flatMap(item => item?.assets ?? [])]
                }
                return []
            }}
            renderItemComponent={(isGrid, params) => {
                const ItemComponent = isGrid ? AssetCard : AssetTableRow
                return (
                    <ItemComponent
                        {...params}
                        netStakingFlowItem={getNetStakingFlowItem(
                            metricsItems,
                            params?.item
                        )}
                        providerCount={providerCounts?.[params?.item?.slug]}
                        stablecoinData={stablecoinData?.[params?.item?.slug]}
                    />
                )
            }}
        />
    )
}

export const CardsOnlyAssetsTable = () => {
    const { paginationParams } = useTableArchiveContext()

    const pageSizeNumber = Number(
        paginationParams?.pageSize?.key ?? PageSizes.Twenty
    )

    const { data, isLoading, noDataFound } = useAssetsData()

    const startingIndex = 0

    const isLoadingData = !data && isLoading

    let rowItems = []
    let metricsItems = []
    if (isLoadingData) {
        rowItems = [...Array(pageSizeNumber).keys()]
    } else if (data?.length) {
        const allItems = [...data]
        if (Boolean(allItems?.length)) {
            rowItems = [...allItems.flatMap(item => item?.assets ?? [])]
            metricsItems = [
                ...allItems.flatMap(item => item?.assetsWithMetrics ?? []),
            ]
        }
    }

    if (!isLoading && noDataFound) {
        return (
            <div className={styles.statusWrap}>
                <p>
                    There are no results for your search. Please revise your
                    filters.
                </p>
            </div>
        )
    }

    return (
        <div className={styles.grid}>
            {rowItems?.map((item, idx) => (
                <AssetCard
                    key={`item-${item?.slug ?? ''}-${startingIndex + idx + 1}`}
                    index={startingIndex + idx + 1}
                    item={isLoadingData ? null : item}
                    isLoading={isLoadingData}
                    netStakingFlowItem={getNetStakingFlowItem(
                        metricsItems,
                        item
                    )}
                    minimal={false}
                />
            ))}
        </div>
    )
}

function getRewardRates(data) {
    const rewardRates = {}

    // Iterate over each reward option in the data
    for (const [rewardOption, details] of Object.entries(data)) {
        let bestRate = null
        let worstRate = null
        const providers = []

        // Iterate over each entry in the reward option array
        details.forEach(entry => {
            if (entry.metrics) {
                entry.metrics.forEach(metric => {
                    if (metric.metricKey === 'reward_rate') {
                        const rate = metric.defaultValue
                        if (bestRate === null || rate > bestRate) {
                            bestRate = rate
                        }
                        if (worstRate === null || rate < worstRate) {
                            worstRate = rate
                        }
                    }
                })
            }
            if (
                entry?.providers &&
                providers?.length < 5 &&
                !providers.find(p => p.id === entry.providers[0].id)
            ) {
                providers.push(...entry.providers)
            }
        })

        const formattedKey = rewardOption.replaceAll('__', '-')
        // Store the best and worst reward rates for the current reward option
        rewardRates[formattedKey] = {
            bestRate: bestRate,
            worstRate: worstRate,
            providers,
        }
    }

    return rewardRates
}
